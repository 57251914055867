import { ChangeDetectionStrategy, Component, computed, inject, Optional, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Router, RouterModule } from '@angular/router';
import { GthSocialButtonsModule } from '@gth-legacy';
import { APP_ROUTES } from '@shared/helpers';

import { AuthLayoutComponent } from '../../layout/auth-layout.component';
import { ForgotPasswordService } from './services/forgot-password.service';

const leftImage = {
  url: 'assets/soccer2.webp',
  alt: 'People playiong soccer',
};

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
  imports: [RouterModule, MatFormFieldModule, MatInputModule, MatButtonModule,
     FormsModule, ReactiveFormsModule, GthSocialButtonsModule, MatProgressSpinner],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent {
  private forgotPasswordService = inject(ForgotPasswordService);
  private router = inject(Router);

  public loading = this.forgotPasswordService.loading;
  public inDialog = signal(this.router.url.includes('auth'));

  public signUpRoute = computed(() =>
    this.inDialog() ? `${this.routes.Auth}/sign-up` : this.routes.SignUp,
  );

  emailFormControl = new UntypedFormControl(
    null,
    { validators: [Validators.required, Validators.email] },
  );
  protected readonly routes = APP_ROUTES;

  constructor(@Optional() layout: AuthLayoutComponent) {
    if (!this.inDialog()) {
      layout.image.set(leftImage);
    }
  }

  onResetPasswordSubmit() {
    const email = this.emailFormControl.value;
    this.forgotPasswordService.onResetPasswordSubmit(email);
  }

  onGoogleLoginBtnClick() {
    this.forgotPasswordService.onGoogleLoginBtnClick();
  }
}
