import { inject, Injectable, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GthAuthService } from '@gth-legacy';
import { AuthError } from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  private authService = inject(GthAuthService);
  private snackbar = inject(MatSnackBar);

  public loading = signal(false);

  // TODO(srevier): Convert to observable
  onResetPasswordSubmit(email?: string) {
    if (!email) return;
    this.loading.set(true);
    this.authService.sendPasswordResetEmail(email)
      .then(() => {
        this.snackbar.open(`Password reset email sent`);
        this.loading.set(false);
      })
      .catch((error: AuthError) => {
        this.snackbar.open(`Something went wrong sending password reset email`, 'OK');
        return error;
      });
  }

  onGoogleLoginBtnClick() {
    this.authService.storeGoogleLogin();
  }
}
